<template>
    <div>
        <Animation v-model="value" fadeIn class="fixed z-50">
            <div ref="overlay" @click="onClick" class="fixed inset-0 bg-black bg-opacity-20 flex justify-center" :class="[fullsize ? 'items-start overflow-auto' : 'items-center']">
                <Animation v-model="value" fadeIn :slideDown="!fullsize" :slideDownHard="fullsize" :class="{ 'w-full': fullsize }">
                    <div ref="popup" role="dialog" class="customDialog overflow-auto relative bg-cream shadow md:px-2 px-3 py-3 rounded w-[980px]" :class="[{ 'w-min': small, 'w-full min-h-screen rounded-none p-0 !max-w-none !max-h-none': fullsize }, contentClass, isMedical ? '!bg-[#D3F0FF]' : 'bg-cream']">
                        <section v-if="fullsize && title" class="sticky top-0 z-20 bg-cream border-b-2 border-light-grey mx-2 md:mx-3 !pb-2 md:!pb-3">
                            <h2 class="text-center">{{ title }}</h2>
                            <div class="flex items-end justify-end h-0">
                                <button class="relative left-2 top-05 h2 p-05 cursor-pointer rounded-full hover:bg-light-grey leading-0" @click="close" tabindex="1">
                                    <icon icon="mdi:close" data-testid="popup.close" />
                                </button>
                            </div>
                        </section>
                        <div v-else class="flex justify-end">
                            <button class="cursor-pointer absolute top-1 right-1 md:top-2 md:right-2" @click="close" tabindex="1">
                                <icon icon="mdi:close" class="h-[24px] w-auto" data-testid="popup.close" />
                            </button>
                        </div>
                        <slot></slot>
                    </div>
                </Animation>
            </div>
        </Animation>
    </div>
</template>

<script>
    import Animation from "~/components/library/Animation.vue"

    export default {
        components: { Animation },
        props: {
            value: Boolean,
            small: Boolean,
            fullsize: Boolean,
            title: String,
            contentClass: String,
            noOutsideClickClose: Boolean,
            isMedical: {
                type: Boolean,
                default: false,
            },
        },
        mounted() {
            document.addEventListener("keydown", this.onKeyDown)
        },
        destroyed() {
            document.removeEventListener("keydown", this.onKeyDown)
        },
        methods: {
            close() {
                this.$emit("input", false)
            },
            onClick(e) {
                if (e.target === this.$refs.overlay && !this.noOutsideClickClose) this.close()
            },
            onKeyDown(e) {
                if (e.key === "Escape") this.close()
            },
        },
    }
</script>

<style lang="scss" scoped>
    .customDialog {
        min-width: calc(min(400px, calc(100vw - #{var(--space-2)} - #{var(--space-2)})));
        max-width: calc(100vw - #{var(--space-2)} - #{var(--space-2)});
        max-height: calc(100vh - #{var(--space-2)} - #{var(--space-2)});
    }
</style>
